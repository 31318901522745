import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/fp/omit';

import { isDesktop } from '../../utils/deviceUtils';
import OverlayTrigger from '../overlay/OverlayTrigger';

const Tooltip = React.forwardRef((props, ref) => {
    const {
        allowOnTouch,
        arrowProps,
        children,
        className,
        placement,
        style,
        textAlignment,
        tooltipStyle,
        width,
        ...remainingProps
    } = omit(['popper', 'positionLeft', 'positionTop'], props);

    if (!isDesktop() && !allowOnTouch) {
        return null;
    }

    const wrapperClasses = classNames('tooltip', tooltipStyle && `tooltip-${tooltipStyle}`, placement, className);
    const outerStyle = {
        ...style,
    };

    const innerClasses = classNames(
        'tooltip-inner',
        textAlignment && `text-${textAlignment}`,
        width && `width-${width}`
    );

    return (
        <div {...remainingProps}
            role={'tooltip'}
            // x-placement is used by the css to define how to position the arrow.
            x-placement={placement}
            ref={ref}
            className={wrapperClasses}
            style={outerStyle}
            data-offset={40}
        >
            <div className={'tooltip-arrow'} {...arrowProps} />
            <div className={innerClasses}>
                {children}
            </div>
        </div>
    );
});

Tooltip.STYLE_DEFAULT = 'default';
Tooltip.STYLE_ONBOARDING = 'onboarding';

Tooltip.defaultProps = {
    placement: Tooltip.BOTTOM,
    className: '',
    textAlignment: 'center',
    tooltipStyle: Tooltip.STYLE_DEFAULT,
    width: null,
    allowOnTouch: false,
};

Tooltip.AUTO_START = OverlayTrigger.AUTO_START;
Tooltip.AUTO = OverlayTrigger.AUTO;
Tooltip.AUTO_END = OverlayTrigger.AUTO_END;

Tooltip.TOP_START = OverlayTrigger.TOP_START;
Tooltip.TOP = OverlayTrigger.TOP;
Tooltip.TOP_END = OverlayTrigger.TOP_END;

Tooltip.BOTTOM_START = OverlayTrigger.BOTTOM_START;
Tooltip.BOTTOM = OverlayTrigger.BOTTOM;
Tooltip.BOTTOM_END = OverlayTrigger.BOTTOM_END;

Tooltip.RIGHT_START = OverlayTrigger.RIGHT_START;
Tooltip.RIGHT = OverlayTrigger.RIGHT;
Tooltip.RIGHT_END = OverlayTrigger.RIGHT_END;

Tooltip.LEFT_START = OverlayTrigger.LEFT_START;
Tooltip.LEFT = OverlayTrigger.LEFT;
Tooltip.LEFT_END = OverlayTrigger.LEFT_END;

export const TooltipPlacementType = PropTypes.oneOf([
    Tooltip.AUTO_START,
    Tooltip.AUTO,
    Tooltip.AUTO_END,
    Tooltip.TOP_START,
    Tooltip.TOP,
    Tooltip.TOP_END,
    Tooltip.BOTTOM_START,
    Tooltip.BOTTOM,
    Tooltip.BOTTOM_END,
    Tooltip.RIGHT_START,
    Tooltip.RIGHT,
    Tooltip.RIGHT_END,
    Tooltip.LEFT_START,
    Tooltip.LEFT,
    Tooltip.LEFT_END,
]);

Tooltip.propTypes = {
    placement: TooltipPlacementType,
    className: PropTypes.string,
    allowOnTouch: PropTypes.bool,
    tooltipStyle: PropTypes.oneOf([Tooltip.STYLE_DEFAULT, Tooltip.STYLE_ONBOARDING]),
    textAlignment: PropTypes.oneOf(['left', 'center', 'right']),
    width: PropTypes.oneOf(['auto', 100, 150, 200, 250, 300, 350, 400, 450, 500]),
};

export default Tooltip;
