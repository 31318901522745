import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import debounce from 'lodash/fp/debounce';
import classNames from 'classnames';

import useEffectOnce from '../../hooks/useEffectOnce';
import ClearableInput from '../clearableInput/ClearableInput';
import ExpanderPanel from '../expander/ExpanderPanel';

// @screen-ls: 480px;
// @screen-sm: 768px;

const RESIZE_THROTTELING = 10;
const MOBILE_MAX_WIDTH = 580;

const filterMenuItems = (items, value) => {
    return items.map(menuGroupItem => {
        const groupItems = menuGroupItem.navItems;
        const navItems = groupItems.filter(item => item.key.toLowerCase().includes(value.toLowerCase()));
        return {
            ...menuGroupItem,
            navItems,
        };
    });
};

const ListMenuHeader = ({ group, className = '', groupNavItem }) => {
    if (!group && !groupNavItem) {
        return null;
    }

    return (
        <li className={'ListMenuHeader'}>{groupNavItem ? groupNavItem : <span className={className}>{group}</span>}</li>
    );
};

const ListMenuItem = ({ disabled, children }) => <li className={disabled ? 'disabled' : ''}>{children}</li>;

const ListMenuGroup = ({ menuGroup, className }) => {
    if (!menuGroup.navItems.length) {
        return null;
    }

    return (
        <ul className={`ListMenuGroup ${className}`}>
            {<ListMenuHeader {...menuGroup} />}
            {menuGroup.navItems.map(item => (
                <ListMenuItem key={item.key} disabled={item.disabled}>
                    {item.item}
                </ListMenuItem>
            ))}
        </ul>
    );
};

const NotFoundMessage = ({ notFoundMessage }) => (
    <div className={'padding-top-25 text-center text-color-gray'}>{notFoundMessage}</div>
);

const ListMenu = props => {
    const {
        menuItems,
        focusFilter,
        enableFilter,
        filterPlaceholder,
        notFoundMessage,
        className,
        groupClassName,
        responsive,
        autoClose,
    } = props;

    const [filterValue, setFilterValue] = useState('');
    const [isMobileMode, setIsMobileMode] = useState(false);
    const [isExpanderOpen, setIsExpanderOpen] = useState(false);
    const [mobileHeader, setMobileHeader] = useState('');

    const inputRef = useRef();
    const listRef = useRef();

    const buildMobileHeader = () => {
        if (responsive && listRef.current) {
            const [activeElement] = listRef.current.getElementsByClassName('active');
            setMobileHeader(
                <div className={'display-flex align-items-center'}>
                    <span className={'rioglyph rioglyph-menu-hamburger margin-right-10'} />
                    <span>{activeElement && activeElement.innerText}</span>
                </div>
            );
        }
    };

    const handleMobileMode = () => setIsMobileMode(window.innerWidth < MOBILE_MAX_WIDTH);

    useEffectOnce(() => {
        const handleKeyDown = event => {
            // clear on esc
            if (event.keyCode === 27 && enableFilter && inputRef.current === document.activeElement) {
                setFilterValue('');
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    });

    useEffectOnce(() => {
        handleMobileMode();

        const handleResize = debounce(RESIZE_THROTTELING)(() => {
            handleMobileMode();
            buildMobileHeader();
        });

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    useLayoutEffect(() => {
        buildMobileHeader();
    }, [menuItems]);

    useEffectOnce(() => {
        focusFilter && inputRef.current && inputRef.current.focus();
    });

    const handleClear = () => focusFilter && inputRef.current && inputRef.current.focus();

    const handleFilterChange = value => setFilterValue(value);

    const hasMenuItems = items => items.find(menuGroup => !isEmpty(menuGroup.navItems));

    const filteredMenuItems = filterMenuItems(menuItems, filterValue);

    const handleExpanderBodyClick = event => {
        const isListItem = event.target.parentElement.tagName.toLowerCase() === 'li';
        autoClose && isListItem && setIsExpanderOpen(false);
    };

    const formClassNames = classNames(
        'from-group',
        'padding-left-15',
        'padding-right-15',
        'padding-bottom-15',
        'position-sticky',
        'top-0'
    );

    const listMenu = (
        <div className={`ListMenu ${className}`} ref={listRef}>
            {enableFilter && (
                <div className={formClassNames}>
                    <div className={'input-group width-100pct'}>
                        <span className={'input-group-addon'}>
                            <span className={'rioglyph rioglyph-search'} aria-hidden={'true'} />
                        </span>
                        <ClearableInput
                            value={filterValue}
                            inputRef={inputRef}
                            placeholder={filterPlaceholder}
                            onChange={handleFilterChange}
                            onClear={handleClear}
                        />
                    </div>
                </div>
            )}
            {!hasMenuItems(filteredMenuItems) && <NotFoundMessage notFoundMessage={notFoundMessage} />}
            {filteredMenuItems.map((menuGroup, index) => (
                <ListMenuGroup className={groupClassName} menuGroup={menuGroup} key={index} />
            ))}
        </div>
    );

    if (responsive && isMobileMode) {
        return (
            <ExpanderPanel
                title={mobileHeader}
                bsStyle={'default'}
                open={isExpanderOpen}
                onToggle={() => setIsExpanderOpen(!isExpanderOpen)}
                mountOnEnter={false}
                unmountOnExit={false}
                className={'shadow-default'}
            >
                <div onClick={handleExpanderBodyClick}>{listMenu}</div>
            </ExpanderPanel>
        );
    }

    return listMenu;
};

ListMenu.defaultProps = {
    menuItems: [],
    enableFilter: false,
    focusFilter: false,
    className: '',
    groupClassName: '',
    responsive: true,
    autoClose: true,
};

ListMenu.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            group: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            groupNavItem: PropTypes.node,
            navItems: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.string.isRequired,
                    item: PropTypes.node.isRequired,
                    disabled: PropTypes.bool,
                })
            ).isRequired,
        })
    ).isRequired,
    enableFilter: PropTypes.bool,
    focusFilter: PropTypes.bool,
    filterPlaceholder: PropTypes.string,
    notFoundMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    groupClassName: PropTypes.string,
    className: PropTypes.string,
    responsive: PropTypes.bool,
    autoClose: PropTypes.bool,
};

export default ListMenu;
