/* eslint-disable no-use-before-define */
import React, { useReducer, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import map from 'lodash/fp/map';
import find from 'lodash/fp/find';
import orderBy from 'lodash/fp/orderBy';
import isObject from 'lodash/fp/isObject';
import debounce from 'lodash/fp/debounce';
import cond from 'lodash/fp/cond';
import without from 'lodash/fp/without';
import countBy from 'lodash/fp/countBy';
import getOr from 'lodash/fp/getOr';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import size from 'lodash/fp/size';
import stubTrue from 'lodash/fp/stubTrue';
import omit from 'lodash/fp/omit';
import negate from 'lodash/fp/negate';

import TreeSearch from './TreeSearch';
import TreeSelectAll from './TreeSelectAll';
import TreeSummary from './TreeSummary';
import TreeNodeContainer from './TreeNodeContainer';
import TreeNode from './TreeNode';
import TreeLeafList from './TreeLeafList';
import TreeNothingFound from './TreeNothingFound';
import TreeOptions from './TreeOptions';
import TreeRoot from './TreeRoot';

const SEARCH_DEBOUNCE = 100;

const otherwise = stubTrue;

const notEqual = negate(isEqual);
const notEmpty = negate(isEmpty);

const isNameObject = item => isObject(item.name);
const getFullName = item => `${item.name.firstName} ${item.name.lastName}`;
const getName = item => (isNameObject(item) ? getFullName(item) : item.name);

const getLowerCaseName = item => getOr('', 'name')(item).toLowerCase();
const getLowerCaseLastName = item => getOr('', 'name.lastName')(item).toLowerCase();

const orderByName = orderBy(
    [item => (isNameObject(item) ? getLowerCaseLastName(item) : getLowerCaseName(item))],
    ['asc']
);

const filterEmptyGroups = filter(group => notEmpty(group.items));

const sortGroupsByName = groups => {
    const fixedGroups = {};
    const sortableGroups = {};

    map(group => {
        if (group.position === 'last') {
            fixedGroups[group.id] = { ...group };
        } else {
            sortableGroups[group.id] = { ...group };
        }
    })(groups);

    const sortedGroups = orderByName(sortableGroups);

    return isEmpty(fixedGroups) ? sortedGroups : { ...sortedGroups, ...fixedGroups };
};

const sortGroupItemsByName = groups => {
    const sortedGroups = {};
    map(group => {
        sortedGroups[group.id] = {
            ...group,
            items: orderByName(group.items),
        };
    })(groups);
    return sortedGroups;
};

const getMappedItemsToGroups = (groups, items) => {
    const mappedGroups = {};

    // build an object for listing the groups by id
    groups.forEach(group => {
        mappedGroups[group.id] = {
            ...group,
            items: [],
        };
    });

    items.forEach(item => {
        // add items to the respective group
        const groupIds = item.groupIds || [];
        groupIds.forEach(groupId => {
            const mappedGroup = mappedGroups[groupId];
            if (mappedGroup) {
                mappedGroup.items.push(item);
            }
        });
    });

    return mappedGroups;
};

const filterByName = searchValue => item => {
    if (searchValue) {
        return getName(item).toLowerCase().includes(searchValue.toLowerCase());
    }
    return true;
};

const filterOutByItemId = list => item => !list.includes(item.id);

const containsItemById = list => item => list.includes(item.id);

const getFlatItems = (items, searchValue) => flow(filter(filterByName(searchValue)), orderByName)(items);

const getListIds = list => list.map(listItem => listItem.id);
export const excludeFromList = (list, itemId) => list.filter(item => item !== itemId);

const getAssetTypeCounts = items => countBy(item => item.type, items);

const debounceFn = fn => debounce(SEARCH_DEBOUNCE, fn);

const filterProps = omit([
    'expandedGroups',
    'onExpandGroupsChange',
    'onSearchChange',
    'onSelectionChange',
    'treeOptions',
]);

const customCompare = (prevProps, nextProps) => isEqual(filterProps(prevProps), filterProps(nextProps));

const assetCounted = payload => ({ type: 'assetCounted', payload });
const allChecked = payload => ({ type: 'allChecked', payload });
const searchValueChanged = payload => ({ type: 'searchValueChanged', payload });
const flatItemsChanged = payload => ({ type: 'flatItemsChanged', payload });
const groupedItemsChanged = payload => ({ type: 'groupedItemsChanged', payload });

const treeReducer = (state, action) => {
    switch (action.type) {
        case 'assetCounted':
            return { ...state, assetCounts: action.payload };
        case 'allChecked':
            return { ...state, allChecked: action.payload };
        case 'searchValueChanged':
            return { ...state, searchValue: action.payload, allChecked: false };
        case 'flatItemsChanged':
            return { ...state, flatItems: action.payload };
        case 'groupedItemsChanged':
            return { ...state, groupedItems: action.payload };
        default:
            throw new Error();
    }
};

const Tree = React.memo(props => {
    const {
        groups,
        items,
        selectedGroups,
        selectedItems,
        onSelectionChange,
        hasMultiselect,
        showRadioButtons,
        hideSearch,
        hideTreeHead,
        summary,
        hideSummary,
        search,
        searchPlaceholder,
        onSearchChange,
        className,
        scrollHeight,
        expandedGroups,
        onExpandGroupsChange,
        showEmptyGroups,
        treeOptions,
    } = props;

    const [state, dispatch] = useReducer(treeReducer, {
        groupedItems: [],
        flatItems: [],
        allChecked: false,
        searchValue: '',
        assetCounts: {},
    });

    const treeRef = useRef();

    const previousItems = useRef();
    const previousGroups = useRef();
    const previousSearchValue = useRef('');

    const internalExpandedGroups = useRef(expandedGroups);

    useEffect(() => {
        // Update Tree when items or groups have changed
        if (notEqual(previousItems.current, items) || notEqual(previousGroups.current, groups)) {
            previousItems.current = items;
            previousGroups.current = groups;

            dispatch(assetCounted(getAssetTypeCounts(items)));
            dispatch(allChecked(checkAllSelected({ items, groups, selectedItems, selectedGroups }, state.flatItems)));

            makeTree(groups, items);
        }
    }, [items, groups]);

    const debouncedMakeTree = debounceFn((g, i) => makeTree(g, i));

    useEffect(() => {
        // To prevent executing the effect on first render, use a ref to check previous render values
        if (notEqual(previousSearchValue.current, state.searchValue)) {
            debouncedMakeTree(groups, items);
            previousSearchValue.current = state.searchValue;
        }
    }, [state.searchValue]);

    useEffect(() => {
        makeTree(groups, items);
    }, [showEmptyGroups]);

    const checkAllSelected = (updatedProps, flatItems) => {
        const {
            items: updatedItems,
            groups: updatedGroups,
            selectedItems: updatedSelectedItems,
            selectedGroups: updatedSlectedGroups,
        } = updatedProps;

        if (
            (!hasGroups() && isEmpty(updatedSelectedItems)) ||
            (hasNoSearchAndGroups() && isEmpty(updatedSlectedGroups)) ||
            (hasSearchAndGroups() && isEmpty(updatedSelectedItems))
        ) {
            return false;
        }

        if (hasNoSearchAndGroups()) {
            const unselectedGroups = filter(filterOutByItemId(updatedSlectedGroups))(updatedGroups);
            return isEmpty(unselectedGroups);
        } else if (hasSearchAndGroups()) {
            const unselectedSearchItems = filter(filterOutByItemId(updatedSelectedItems))(flatItems);
            return isEmpty(unselectedSearchItems);
        }

        const unselectedItems = updatedItems.filter(filterOutByItemId(updatedSelectedItems));
        return isEmpty(unselectedItems);
    };

    const handleToggleNode = nodeId => {
        const newExpandedNodes = internalExpandedGroups.current.includes(nodeId)
            ? internalExpandedGroups.current.filter(item => item !== nodeId)
            : [...internalExpandedGroups.current, nodeId];

        // Performance improvement to skip on render cycle and change "open" class directly
        if (internalExpandedGroups.current.includes(nodeId)) {
            getNodeContainerDomElementById(nodeId).classList.remove('open');
        } else {
            getNodeContainerDomElementById(nodeId).classList.add('open');
        }

        internalExpandedGroups.current = newExpandedNodes;
        onExpandGroupsChange(newExpandedNodes);
    };

    const getNodeContainerDomElementById = nodeId => {
        return treeRef.current.querySelector(`.TreeNodeContainer[data-id="${nodeId}"]`);
    };

    const selectAllSearchResultItems = shouldSelect => selectAllFlatItems(shouldSelect);

    const handeSelectAll = (shouldSelect, isIndeterminate) => {
        const shouldSelectAll = shouldSelect && !isIndeterminate;
        dispatch(allChecked(shouldSelectAll));

        cond([
            [hasNoSearchAndGroups, () => selectAllGroups(shouldSelectAll)],
            [hasSearchAndGroups, () => selectAllSearchResultItems(shouldSelectAll)],
            [otherwise, () => selectAllFlatItems(shouldSelectAll)],
        ])();
    };

    const selectAllGroups = shouldSelect => respondSelection([], shouldSelect ? getListIds(groups) : []);
    const selectAllFlatItems = shouldSelect => respondSelection(shouldSelect ? getListIds(state.flatItems) : [], []);

    const respondSelection = (updatedSelectedItems, updatedSelectedGroups) => {
        onSelectionChange({ items: updatedSelectedItems, groups: updatedSelectedGroups });
    };

    const handleGroupSelection = (group, isIndeterminate) => {
        const groupId = group.id;

        const isSelected = selectedGroups.includes(groupId);
        const shouldSelectGroup = !isSelected && !isIndeterminate;

        // handle group selection
        const newSelectedGroups = shouldSelectGroup
            ? [...selectedGroups, groupId]
            : excludeFromList(selectedGroups, groupId);

        // deselect all items of a node since they will be selected inherently via the group itself
        const itemsInGroup = find(entry => entry.id === groupId)(state.groupedItems);
        const itemIdsOfGroup = map(item => item.id)(itemsInGroup.items);
        const updatedSelectedItems = without(itemIdsOfGroup, selectedItems);

        respondSelection(updatedSelectedItems, newSelectedGroups);
    };

    const handleSearchChange = updatedSearchValue => {
        onSearchChange(updatedSearchValue);
        dispatch(searchValueChanged(updatedSearchValue));
    };

    const hasGroups = () => groups && notEmpty(groups);
    const hasSearchAndGroups = () => hasInternalSearchValue() && hasGroups();
    const hasNoSearchAndGroups = () => !hasInternalSearchValue() && hasGroups();

    const setFlatItemList = (updtedItems, searchValue) => {
        const flatItems = getFlatItems(updtedItems, searchValue);
        dispatch(assetCounted(getAssetTypeCounts(flatItems)));
        dispatch(flatItemsChanged(flatItems));
    };

    const setGroupedItemList = (groupsToProcess, itemsToProcess, considerEmptyGroups) => {
        // Map items to groups with filtered items
        const mappedItemsToGroups = getMappedItemsToGroups(groupsToProcess, itemsToProcess);
        const newGroupedItems = flow(sortGroupsByName, sortGroupItemsByName)(mappedItemsToGroups);
        const groupedItems = considerEmptyGroups ? newGroupedItems : filterEmptyGroups(newGroupedItems);

        dispatch(assetCounted(getAssetTypeCounts(items)));
        dispatch(groupedItemsChanged(groupedItems));
    };

    const makeTree = (updatedGroups, updatedItems) => {
        const internalSearchValue = state.searchValue;

        const groupsToProcess = updatedGroups;
        const itemsToProcess = updatedItems;

        const hasGroupList = groupList => groupList && notEmpty(groupList);

        const hasNoInternalSearchAndGroups = () => isEmpty(internalSearchValue) && hasGroupList(groupsToProcess);
        const hasInternalSearchAndGroups = () => notEmpty(internalSearchValue) && hasGroupList(groupsToProcess);

        const setGroupedItems = () => setGroupedItemList(groupsToProcess, itemsToProcess, showEmptyGroups);
        const setFlatItems = () => setFlatItemList(itemsToProcess, internalSearchValue);

        cond([
            [hasNoInternalSearchAndGroups, setGroupedItems],
            [hasInternalSearchAndGroups, setFlatItems],
            [otherwise, setFlatItems],
        ])();
    };

    const renderTree = () => {
        const { groupedItems } = state;

        if (isEmpty(groupedItems)) {
            return <TreeNothingFound />;
        }

        const result = map(group => {
            const groupId = group.id;
            const groupItems = group.items;

            const isOpen = expandedGroups.includes(groupId);

            const numSelectedGroupItems = filter(containsItemById(selectedItems))(groupItems).length;

            const isGroupSelected = selectedGroups.includes(groupId);
            const isIndeterminate = !isGroupSelected && numSelectedGroupItems > 0;

            return (
                <TreeNodeContainer key={groupId} groupId={groupId} isOpen={isOpen}>
                    <TreeNode
                        node={group}
                        hasMultiselect={hasMultiselect}
                        onToggleNode={handleToggleNode}
                        onSelect={handleGroupSelection}
                        isSelected={isGroupSelected}
                        isIndeterminate={isIndeterminate}
                    />
                    <TreeLeafList
                        leafList={groupItems}
                        hasMultiselect={hasMultiselect}
                        showRadioButtons={showRadioButtons}
                        selectedItems={selectedItems}
                        selectedGroups={selectedGroups}
                        onSelectionChange={respondSelection}
                    />
                </TreeNodeContainer>
            );
        })(groupedItems);

        return result;
    };

    const renderFlatList = () => {
        const { flatItems } = state;
        const hasLeafs = isEmpty(flatItems);

        const getLeafs = () => (
            <TreeLeafList
                leafList={flatItems}
                hasMultiselect={hasMultiselect}
                showRadioButtons={showRadioButtons}
                selectedItems={selectedItems}
                selectedGroups={selectedGroups}
                onSelectionChange={respondSelection}
            />
        );

        return <TreeNodeContainer isOpen>{hasLeafs ? <TreeNothingFound /> : getLeafs()}</TreeNodeContainer>;
    };

    const renderSummary = () => {
        if (hideSummary) {
            return null;
        }
        return summary || <TreeSummary assetCounts={state.assetCounts} />;
    };

    const hasExternalGroups = notEmpty(groups);

    const hasInternalSearchValue = () => notEmpty(state.searchValue);

    const hasSelectedAllItems = () => isEqual(size(selectedItems), size(state.flatItems));

    const hasPartialySelectedItems = () => notEmpty(selectedItems) && !hasSelectedAllItems();

    const hasSelectedAllGroups = () => isEqual(size(selectedGroups), size(groups));

    const hasPartialySelectedGroups = () => hasExternalGroups && notEmpty(selectedGroups) && !hasSelectedAllGroups();

    const hasSearchAndNoItems = hasInternalSearchValue() && isEmpty(state.flatItems);
    const hasSearchAndNoGroups = hasInternalSearchValue() && isEmpty(state.groupedItems) && hasExternalGroups;
    const hideSelectAll = hasSearchAndNoItems || hasSearchAndNoGroups;

    const isIndeterminate = hasPartialySelectedGroups() || hasPartialySelectedItems();

    const treeClassNames = classNames('Tree', className);

    const treeHeadClasses = classNames('TreeHead', 'display-flex align-items-center', 'padding-15');

    const shouldRenderTree = () => hasGroups() && !hasInternalSearchValue();

    const content = cond([
        [shouldRenderTree, () => renderTree()],
        [otherwise, () => renderFlatList()],
    ])();

    return (
        <div className={treeClassNames} ref={treeRef}>
            <div className={'TreeHeader'}>
                {!hideSearch && !search && (
                    <TreeSearch
                        value={state.searchValue}
                        onChange={handleSearchChange}
                        placeholder={searchPlaceholder}
                    />
                )}
                {search && search}
                {!hideTreeHead && (
                    <div className={treeHeadClasses}>
                        {!hideSelectAll && (
                            <TreeSelectAll
                                isChecked={state.allChecked}
                                isEnabled={hasMultiselect}
                                isIndeterminate={isIndeterminate}
                                onSelect={handeSelectAll}
                            />
                        )}
                        <div className={'display-flex flex-row justify-content-between width-100pct'}>
                            {renderSummary()}
                            <TreeOptions treeOptions={treeOptions} />
                        </div>
                    </div>
                )}
            </div>
            <TreeRoot maxHeight={scrollHeight}>{content}</TreeRoot>
        </div>
    );
}, customCompare);

Tree.displayName = 'Tree';

Tree.defaultProps = {
    hasMultiselect: true,
    showRadioButtons: false,
    hideSearch: false,
    hideSummary: false,
    showEmptyGroups: true,
    selectedGroups: [],
    selectedItems: [],
    onSelectionChange: () => {},
    onExpandGroupsChange: () => {},
    onSearchChange: () => {},
    searchPlaceholder: 'Type here to filter by name',
    treeOptions: [],
    groups: [],
};

Tree.propTypes = {
    groups: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            icon: PropTypes.string,
            className: PropTypes.string,
        })
    ),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({
                    firstName: PropTypes.string,
                    lastName: PropTypes.string.isRequired,
                }),
            ]).isRequired,
            info: PropTypes.string,
            type: PropTypes.string.isRequired,
            groupIds: PropTypes.arrayOf(PropTypes.string),
            className: PropTypes.string,
        })
    ),
    selectedGroups: PropTypes.arrayOf(PropTypes.string),
    selectedItems: PropTypes.arrayOf(PropTypes.string),
    onSelectionChange: PropTypes.func,
    hasMultiselect: PropTypes.bool,
    showRadioButtons: PropTypes.bool,
    hideSearch: PropTypes.bool,
    summary: PropTypes.node,
    hideSummary: PropTypes.bool,
    hideTreeHead: PropTypes.bool,
    // This defines a custom search component.
    search: PropTypes.node,
    searchPlaceholder: PropTypes.string,
    onSearchChange: PropTypes.func,
    className: PropTypes.string,
    scrollHeight: PropTypes.number,
    expandedGroups: PropTypes.arrayOf(PropTypes.string),
    onExpandGroupsChange: PropTypes.func,
    showEmptyGroups: PropTypes.bool,
    treeOptions: PropTypes.arrayOf(PropTypes.node),
};

export default Tree;
