import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isNil from 'lodash/fp/isNil';

import Fade from '../fade/Fade';

const BaseSpinner = ({ isDoubleSized, isFullScreen, isInverse }) => {
    const hasDoubleSized = isDoubleSized || isFullScreen;

    const baseSpinnerClasses = classNames('spinner', hasDoubleSized && 'double', isInverse && 'inverse');

    return (
        <div className={baseSpinnerClasses}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

const Spinner = props => {
    const { text, isInverse, isFullSized, isFullScreen, show, isInline } = props;

    const spinnerWrapperClasses = classNames(
        'spinnerInfoBox',
        isFullSized && 'fullsized',
        isFullScreen && 'fullscreen',
        isInverse && 'inverse',
        'display-flex justify-content-center align-items-center height-100pct'
    );

    if (isInline) {
        return <BaseSpinner {...props} />;
    }

    const wrappedSpinner = (
        <div className={spinnerWrapperClasses}>
            <BaseSpinner {...props} />
            {text && <div className={'spinnerText'}>{text}</div>}
        </div>
    );

    if (!isNil(show) && (isFullScreen || isFullSized)) {
        return (
            <Fade in={show} timeout={100} mountOnEnter unmountOnExit>
                {wrappedSpinner}
            </Fade>
        );
    }

    return wrappedSpinner;
};

Spinner.defaultProps = {
    isInverse: false,
    isFullSized: false,
    isFullScreen: false,
    isDoubleSized: false,
    isInline: false,
    show: false,
};

Spinner.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isInverse: PropTypes.bool,
    isFullSized: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    isDoubleSized: PropTypes.bool,
    isInline: PropTypes.bool,
    show: PropTypes.bool,
};

export default Spinner;
